import withStyles from '@material-ui/styles/withStyles';
import * as PropTypes from 'prop-types';
import chaseScoreIcon from 'assets/svg/chase_score.svg';
import React, { Component } from 'react';
import Button from 'UI/button';

class ChaseScoreModal extends Component {
  render() {
    const { classes, toggleChaseScoreModal } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <img
            alt="Chase Score"
            src={chaseScoreIcon}
            style={{ height: '18px', width: '18px', marginRight: 5 }}
          />
          Chase Score
        </div>
        <div className={classes.body}>
          <br />
          <p>
            Chase score shows how quickly and effectively you&apos;re working on your leads
            and how close you are to closing bookings.
          </p>
          <br />
          <p>
            <strong>A high Chase Score</strong> means you&apos;re in the game, moving fast,
            chasing the close, and maximizing your earnings.
          </p>
          <br />
          <p>
            <strong>A low score?</strong> That&apos;s a sign you might be leaving money on the table.
          </p>
          <br />
          <div className={classes.heading}>
            <span role="img" aria-label="check">✅</span> Keep Your Score Healthy
          </div>
          <ul className={classes.list}>
            <li>Act fast on new leads, within 24 hours</li>
            <li>Follow up smartly and close customer conversations</li>
            <li>Once you&apos;ve taken requirements, try to send the itinerary within 48 hours.</li>
            <li><strong>Chase the Close</strong>, follow up actively to get to token payment within 7 days</li>
            <li>Mark leads LOST or INVALID only after trying everything</li>
          </ul>
          <p className={classes.quote}>
            <span role="img" aria-label="think">💬</span> <em>&quot;Think of your Chase Score as your business heartbeat. Keep it strong, and your travel biz thrives.&quot;</em>
          </p>
          <Button
            className={classes.okayButton}
            onClick={toggleChaseScoreModal}
          >
            Okay
          </Button>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  body: {
    fontFamily: 'Lato, Arial, sans-serif',
    fontSize: '14px',
    lineHeight: 1.6,
    color: '#333',
    padding: '0px 40px 40px 40px',
  },
  header: {
    padding: '20px 40px',
    display: 'flex',
    fontSize: '18px',
    fontWeight: 'bold',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: '1px solid #DDDDDD',
  },
  heading: {
    fontWeight: 'bold',
  },
  list: {
    paddingLeft: theme.spacing(3),
    margin: 0,
  },
  quote: {
    fontStyle: 'italic',
    color: '#555',
    marginTop: theme.spacing(2),
  },
  okayButton: {
    backgroundColor: '#3AAEA9',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '30px',
    marginTop: 20,
  },
});

ChaseScoreModal.propTypes = {
  classes: PropTypes.object,
  toggleChaseScoreModal: PropTypes.func,
};

export default withStyles(styles)(ChaseScoreModal);
