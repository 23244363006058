import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import LinearProgress from '@material-ui/core/LinearProgress';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import withStyles from '@material-ui/styles/withStyles';
import clsx from 'clsx';
import traineeTierIcon from 'assets/svg/trainer_tier.svg';
import starTierIcon from 'assets/svg/star_tier.svg';
import goldTierIcon from 'assets/svg/gold_tier.svg';
import diamondTierIcon from 'assets/svg/diamond_tier.svg';
import platinumTierIcon from 'assets/svg/platinum_tier.svg';
import conversionRateIcon from 'assets/svg/conversion_rate.svg';
import rightArrowIcon from 'assets/svg/right_arrow.svg';
import * as _ from 'lodash';
import * as moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import create from 'assets/svg/create.svg';

class PerfModal extends Component {
  render() {
    const {
      classes,
      togglePerfModal,
      toggleRequestPackModal,
      expert,
    } = this.props;
    const slabs = expert.slabs;
    const tierName = expert.tierName;
    // eslint-disable-next-line no-nested-ternary
    const tierIcon = tierName === 'TRAINEE'
      ? traineeTierIcon
      // eslint-disable-next-line no-nested-ternary
      : tierName === 'RISING_STAR'
        ? starTierIcon
        // eslint-disable-next-line no-nested-ternary
        : (tierName === 'GOLD'
          ? goldTierIcon
          : (tierName === 'DIAMOND'
            ? diamondTierIcon
            : platinumTierIcon));
    const isEarlyTier = ['TRAINEE', 'RISING_STAR'].includes(tierName);
    const leadsNumerator = (['TRAINEE', 'RISING_STAR'].includes(tierName))
      ? (expert.totalLeadCount - expert.validLeadCount)
      : ((expert.activeRequestPack?.purchasedCount || 0) + (expert.activeRequestPack?.bonusCount || 0) - (expert.activeRequestPack?.assignedCount || 0));
    const leadsDenominator = (['TRAINEE', 'RISING_STAR'].includes(tierName))
      ? (expert.totalLeadCount)
      : ((expert.activeRequestPack?.purchasedCount || 0) + (expert.activeRequestPack?.bonusCount || 0));
    const chaseScore = expert.chaseScore || 0;
    return (
      <div className={classes.performanceOverview}>
        <div className={classes.header}>
          <div style={{
            fontSize: 20,
            fontWeight: 'bold',
          }}
          >
            {isEarlyTier ? '' : `${expert.month} `}Performance Overview
          </div>
          <IconButton className={classes.closeButton} onClick={togglePerfModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <div className={clsx(classes.monthlyRewards, classes.card)}>
            <div className={clsx(classes.cardTitle)}>{isEarlyTier ? '' : 'Monthly '}Rewards</div>
            <div style={{ fontSize: 24, fontWeight: 'bold' }}>
              {expert.rewardText}
            </div>
            <div className={classes.progressContainer}>
              <LinearProgress
                variant="determinate"
                value={Math.round(((isEarlyTier ? expert.allTimeClosedCount : expert.thisMonthClosedCount)/slabs[slabs.length - 1].startCount) * 100)}
                classes={{
                  root: classes.progressBar,
                  bar: classes.progress,
                }}
              />
              {
                slabs.map((slab) => {
                  return (slab.bonusAmount || slab.commissionPercent || slab.graduate)
                    ? (
                      <div className={classes.marker} style={{ left: `${(slab.startCount / slabs[slabs.length - 1].startCount) * 95}%` }} />
                    )
                    : null;
                })
              }
            </div>
            <div className={classes.rewards}>
              {
                slabs.map((slab) => {
                  return (slab.bonusAmount || slab.commissionPercent || slab.graduate)
                    ? (
                      <div className={classes.rewardStep} style={{ left: `${(slab.startCount / slabs[slabs.length - 1].startCount) * 95 - 5}%` }}>
                        <div variant="body2" style={{ fontWeight: 'bold' }}>{slab.startCount}</div>
                        {
                          // eslint-disable-next-line no-nested-ternary
                          slab.bonusAmount
                            ? (<div variant="body2" className={classes.rewardAmount}><i>{expert.tierName ? '+' : ''}₹{_.round(slab.bonusAmount/100).toLocaleString()}</i></div>)
                            : slab.graduate
                              ? (<div variant="body2" className={classes.rewardAmount}><i>Graduate</i></div>)
                              : (<div variant="body2" className={classes.rewardAmount}> &nbsp; </div>)
                        }
                        {
                          slab.commissionPercent
                            ? <div tooltip="Commission" className={classes.commissionPercent}>{slab.commissionPercent}% Comm.</div>
                            : null
                        }
                      </div>
                    )
                    : null;
                })
              }
            </div>
          </div>
          <div className={classes.statEntries}>
            <div style={{ flex: 1 }}>
              <div className={clsx(classes.stat, classes.card)} style={{ height: 286 }}>
                <div className={classes.cardTitle}>
                  {expert.month} Leads Assigned
                </div>
                <Typography
                  variant="h5"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <PersonIcon className={classes.icon} /> {expert.validLeadCount}
                </Typography>
                <div style={{ fontFamily: 'Lato' }}>
                  {
                    expert.leadFlowOn
                      ? (
                        <div style={{
                          color: '#20B038',
                          fontSize: 18,
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 10,
                        }}
                        >
                          <span className={clsx(classes.blinkingDot, classes.greenDot)} />
                          Lead Flow On
                        </div>
                      )
                      : (
                        <div style={{
                          color: '#CE1515',
                          fontSize: 18,
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 10,
                        }}
                        >
                          <span className={clsx(classes.blinkingDot, classes.redDot)} />
                          Lead Flow Off: {expert.leadFlowOffReason}
                        </div>
                      )
                  }
                </div>
                {
                  (leadsDenominator > 0)
                    ? (
                      <div>
                        <div className={classes.progressContainer}>
                          <LinearProgress
                            className={classes.freeLeadsProgress}
                            variant="determinate"
                            value={((leadsNumerator/leadsDenominator) * 100)}
                          />
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: 7 }}>
                            <div className={classes.freeLeadsInfo}>
                              <span style={{ fontSize: 24 }}>
                                {leadsNumerator}
                              </span>
                              {isEarlyTier ? ' Free' : ''} Leads
                            </div>
                            <div style={{ color: '#666666' }}>
                              left out of total {leadsDenominator}{isEarlyTier ? ' lifetime' : ''}{expert.activeRequestPack ? ',' : ''}
                            </div>
                            {
                              expert.activeRequestPack
                                ? (
                                  <div style={{ color: '#666666' }}>
                                    expires <b>{moment(expert.activeRequestPack.confirmedAt).add(expert.activeRequestPack.requestPackOption.durationInDays, 'days').format('Do MMMM YYYY')}</b>
                                  </div>
                                )
                                : null
                            }
                          </div>
                          <div
                            style={{
                              flex: 5,
                              display: 'flex',
                              justifyContent: 'right',
                              alignItems: 'end',
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.getLeadsButton}
                              onClick={() => {
                                if (['TRAINEE', 'RISING_STAR'].includes(tierName)) {
                                  window.open(expert.getLeadsLink, '_blank');
                                } else {
                                  togglePerfModal();
                                  toggleRequestPackModal();
                                }
                              }}
                            >
                              <img
                                src={create}
                                alt="create"
                                className={classes.buttonImg}
                                style={{
                                  width: 12,
                                  height: 12,
                                  marginRight: 10,
                                }}
                              />
                              Get Leads
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : <div style={{ height: 40 }} />
                }
              </div>
            </div>
            <div style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            >
              <div
                className={clsx(
                  classes.stat,
                  classes.card,
                  // eslint-disable-next-line no-nested-ternary
                  chaseScore >= 35
                    ? classes.greenChaseScore
                    : chaseScore >= 25
                      ? classes.orangeChaseScore
                      : classes.redChaseScore
                )}
                style={{ height: 128 }}
              >
                <div className={classes.cardTitle}>Chase Score</div>
                <div
                  id="chaseScoreValue"
                  className={classes.chaseScoreValue}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="36px"
                    height="36px"
                    style={{ marginRight: 5 }}
                    className="chaseScoreIcon"
                  >
                    <path d="M8.43182 8.16216L7.23182 5.53514L4.61364 4.33108L7.23182 3.12703L8.43182 0.5L9.63182 3.12703L12.25 4.33108L9.63182 5.53514L8.43182 8.16216ZM15.5227 11.4459L14.4864 9.20203L12.25 8.16216L14.4864 7.1223L15.5227 4.87838L16.5591 7.1223L18.7955 8.16216L16.5591 9.20203L15.5227 11.4459ZM3.52273 13.6351L2.48636 11.3912L0.25 10.3514L2.48636 9.31149L3.52273 7.06757L4.55909 9.31149L6.79545 10.3514L4.55909 11.3912L3.52273 13.6351ZM4.06818 20.75L2.43182 19.1081L10.6136 10.8986L14.9773 15.277L22.7227 6.54764L24.25 8.08007L14.9773 18.5608L10.6136 14.1824L4.06818 20.75Z" fill="currentColor" />
                  </svg>
                  {chaseScore}
                </div>
              </div>

              <div className={clsx(classes.stat, classes.card)} style={{ height: 128 }}>
                <div className={classes.cardTitle}>Conversion %</div>
                <div className={classes.conversionValue}>
                  <img
                    src={conversionRateIcon}
                    alt="[]"
                    className={classes.icon}
                    style={{ height: 42, width: 42 }}
                  />
                  <div
                    style={{
                      color: expert.conversionPercent >= 4 ? '#28a745' : '#CC3254',
                    }}
                  >
                    {expert.conversionPercent}%
                  </div>
                  <div className={classes.conversionPeriod}>(Last 30 Days)</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.statEntries}>
            <div className={clsx(classes.stat, classes.card)} style={{ flex: 1, height: 128 }}>
              <div className={classes.cardTitle}>{expert.month} Earnings</div>
              <div style={{ fontSize: 36, fontWeight: 'bold' }}>
                ₹{_.round(expert.thisMonthEarnings/100, 2)}
              </div>
            </div>
            <div className={clsx(classes.stat, classes.card)} style={{ flex: 1, height: 128 }}>
              <div className={classes.cardTitle}>{expert.month} Trip Bookings</div>
              <div style={{
                fontSize: 36,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
              }}
              >
                <BusinessCenterIcon className={classes.icon} /> {expert.thisMonthClosedCount}
              </div>
            </div>
          </div>
          <div className={classes.card}>
            {
              expert.tierName
                ? (
                  <>
                    <div className={clsx(classes.cardTitle)}>
                      Milestone Status
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'start',
                        borderBottom: '1px solid #ddd',
                        paddingBottom: 20,
                      }}
                    >
                      <div>
                        <img
                          src={tierIcon}
                          alt="[]"
                          className={classes.tierIcon}
                        />
                      </div>
                      <div style={{ paddingLeft: 20 }}>
                        <div style={{ fontSize: 24, fontWeight: 'bold' }}>{_.startCase(_.lowerCase(expert.tierName))} Club</div>
                        <ul style={{ paddingLeft: 20, color: '#888888' }}>
                          {
                            expert.perks.map((perk) => {
                              return <li>{perk}</li>;
                            })
                          }
                        </ul>
                      </div>
                      {
                        tierName === 'TRAINEE'
                          ? (
                            <div
                              style={{
                                backgroundColor: '#F2B3181A',
                                borderRadius: 12,
                                display: 'flex',
                                flexDirection: 'row',
                                padding: '5px 40px 25px 40px',
                                width: '300px',
                                marginLeft: '30px',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                fontFamily: 'Lato',
                              }}
                            >
                              <div
                                style={{
                                  width: '110px',
                                }}
                              >
                                <div className={classes.progressContainer}>
                                  <LinearProgress
                                    className={classes.traineeBookingsProgress}
                                    variant="determinate"
                                    value={((expert.allTimeClosedCount /_.last(expert.slabs).startCount) * 100)}
                                  />
                                </div>
                                <div>{expert.allTimeClosedCount}/{_.last(expert.slabs).startCount}</div>
                                <div>Bookings done</div>
                              </div>
                              <div
                                style={{
                                  paddingTop: 20,
                                  paddingLeft: 30,
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 48,
                                    lineHeight: '50px',
                                    color: '#E67E22',
                                  }}
                                >
                                  {_.floor(moment(expert.tierEndDate).diff(moment(), 'days'))}
                                </div>
                                <div>Days Left</div>
                              </div>
                            </div>
                          )
                          : null
                      }
                    </div>
                  </>
                )
                : null
            }
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: 15,
              }}
            >
              {
                expert.nextTierText
                  ? (
                    <div style={{ flex: '2' }}>
                      <div style={{ fontSize: 20 }}>
                        <span
                          style={{
                            // eslint-disable-next-line no-nested-ternary
                            color: expert.nextTierName === 'RISING_STAR'
                              ? '#E67E22'
                              : expert.nextTierName === 'DIAMOND'
                                ? '#58ADDB'
                                : '#9FA9B3',
                          }}
                        >
                          {_.startCase(_.lowerCase(expert.nextTierName))} Club
                        </span> {expert.nextTierName === 'RISING_STAR' ? 'up ahead' : 'upgrade'}
                      </div>
                      <p dangerouslySetInnerHTML={{ __html: expert.nextTierText }} />
                    </div>
                  ) : null
              }
              <a
                href={expert.tiersInfoLink}
                target="_blank"
                rel="noreferrer"
                className={classes.milestoneClubsButton}
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  flex: '1',
                }}
              >
                <div>
                  Learn about <br /> milestone clubs
                </div>
                <img
                  src={rightArrowIcon}
                  alt="[]"
                  className={classes.icon}
                  style={{
                    paddingLeft: 10,
                    height: 36,
                    width: 36,
                  }}
                />
              </a>
            </div>
          </div>
          <div
            className={clsx(classes.card)}
          >
            <a
              href="/analytics"
              target="_blank"
              className={classes.detailed}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                See more insights
              </div>
              <img
                src={rightArrowIcon}
                alt="[]"
                className={classes.icon}
                style={{
                  marginTop: 5,
                  marginLeft: 10,
                  height: 36,
                  width: 36,
                }}
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    paddingTop: '12%',
    width: '31%',
    display: 'flex',
    marginRight: 20,
    marginBottom: 20,
    borderRadius: 10,
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: '#EEFFFE',
    boxShadow: '0px 0px 16px -3px rgba(0,0,0,0.2)',
  },
  milestoneClubsButton: {
    fontWeight: 'bolder',
    fontSize: 16,
    color: theme.colors.primary,
    textDecorationColor: theme.colors.primary,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.primary,
      textDecoration: 'none',
    },
  },
  detailed: {
    fontWeight: 'bolder',
    fontSize: 20,
    color: theme.colors.primary,
    textDecorationColor: theme.colors.primary,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.primary,
      textDecoration: 'none',
    },
  },
  progressContainer: {
    position: 'relative',
    marginTop: 20,
    marginBottom: theme.spacing(2),
  },
  progressBar: {
    height: '10px',
    borderRadius: '5px',
    maxWidth: '95%',
    backgroundColor: '#e0e0e0',
  },
  progress: {
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#F2B318',
  },
  traineeBookingsProgress: {
    height: '10px',
    borderRadius: '5px',
  },
  freeLeadsProgress: {
    transform: 'rotate(180deg)',
    height: '10px',
    borderRadius: '5px',
  },
  freeLeadsInfo: {
    color: theme.colors.primary,
  },
  marker: {
    position: 'absolute',
    top: '-3px',
    width: '15px',
    height: '15px',
    backgroundColor: '#F2B318',
    borderRadius: '50%',
    transform: 'translateX(-50%)',
  },
  rewards: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  rewardAmount: {
    color: '#F2B318',
  },
  commissionPercent: {
    color: '#2C7A77',
    fontSize: 14,
  },
  content: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  performanceOverview: {
    fontFamily: 'Lato, Arial, sans-serif',
    width: '900px',
    margin: '0 auto',
    padding: '24px',
    backgroundColor: '#F5F5F5',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  card: {
    margin: '15px 5px 15px 5px',
    padding: '20px 20px',
    borderRadius: 24,
    backgroundColor: '#fff',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#666666',
    marginBottom: 5,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #ddd',
    paddingBottom: '10px',
    marginBottom: '20px',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
  },
  monthlyRewards: {
    height: 210,
  },
  rewardsBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f9f9f9',
    padding: '10px',
    borderRadius: '8px',
  },
  rewardStep: {
    textAlign: 'center',
    fontSize: '18px',
    width: '10%',
    position: 'absolute',
  },
  tierIcon: {
    marginTop: 5,
    height: 48,
    width: 48,
  },
  statEntries: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  stat: {
    textAlign: 'left',
    height: 100,
    color: '#444444',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  statItem: {
    textAlign: 'center',
    fontSize: '14px',
  },
  statValue: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  leadsLeft: {
    marginBottom: '20px',
  },
  highlight: {
    color: '#28a745',
  },
  icon: {
    marginRight: 10,
  },
  conversionValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    fontWeight: 'bold',
    fontSize: '36px',
  },
  conversionPeriod: {
    color: theme.palette.text.secondary,
    marginLeft: '20px',
    fontSize: '16px',
  },
  getLeadsButton: {
    textTransform: 'none',
    borderRadius: 17.5,
    fontSize: 12,
    letterSpacing: 0.5,
    color: theme.colors.black,
    marginRight: 20,
    minHeight: 34,
    height: 34,
    border: `1px solid ${theme.colors.primary}`,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  '@keyframes blink': {
    '0%, 100%': { opacity: 1 },
    '50%': { opacity: 0 },
  },
  blinkingDot: {
    display: 'inline-block',
    width: 16,
    height: 16,
    borderRadius: '50%',
    animation: '$blink 1s infinite',
    marginRight: 10,
  },
  greenDot: {
    backgroundColor: '#20B038',
  },
  redDot: {
    backgroundColor: '#CE1515',
  },
  chaseScoreValue: {
    fontSize: 36,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  greenChaseScore: {
    backgroundColor: '#2C7A771A',
    '& #chaseScoreValue': {
      color: '#2C7A77',
    },
  },
  orangeChaseScore: {
    backgroundColor: '#E67E221A',
    '& #chaseScoreValue': {
      color: '#E67E22',
    },
  },
  redChaseScore: {
    backgroundColor: '#CE15151A', // #CE1515 with 10% opacity
    '& #chaseScoreValue': {
      color: '#CE1515',
    },
  },
});

PerfModal.propTypes = {
  classes: PropTypes.object,
  displayName: PropTypes.string,
  togglePerfModal: PropTypes.func,
  toggleRequestPackModal: PropTypes.func,
  onClick: PropTypes.func,
  tier: PropTypes.string,
  expert: PropTypes.object,
};

export default withStyles(styles)(PerfModal);
