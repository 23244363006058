import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import clsx from 'clsx';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import ListIcon from '@material-ui/icons/List';
import PublicIcon from '@material-ui/icons/Public';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import ReactSpeedometer from 'react-d3-speedometer';
import withStyles from '@material-ui/styles/withStyles';
import flynote from 'assets/svg/logo.svg';
import userOutline from 'assets/svg/user.svg';
import logoutImg from 'assets/svg/logout.svg';
import { ROLES } from 'utils/consts';
import traineeTierIcon from 'assets/svg/trainer_tier.svg';
import starTierIcon from 'assets/svg/star_tier.svg';
import goldTierIcon from 'assets/svg/gold_tier.svg';
import diamondTierIcon from 'assets/svg/diamond_tier.svg';
import platinumTierIcon from 'assets/svg/platinum_tier.svg';
import * as _ from 'lodash';
import Arrow from './arrow';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.detailsRef = React.createRef();
    this.state = {
      detailsPopup: false,
    };
  }

  handleDetailsClose = (event) => {
    if (this.detailsRef.current && this.detailsRef.current.contains(event.target)) {
      return;
    }
    this.setState({ detailsPopup: false });
  };

  handleToggle = (field) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }), () => {
      if (field === 'detailsPopup') {
        setTimeout(this.increaseZIndex, 10);
      }
    });
  };

  increaseZIndex = () => {
    document.getElementsByTagName('body')[0].lastElementChild.style.zIndex = 2;
  };

  actionHandler = (action) => {
    const { actionHandler } = this.props;
    this.setState({ detailsPopup: false });
    actionHandler(action);
  };

  analyticsHandler = () => {
    window.open('/analytics', '_self');
  };

  render() {
    const {
      classes,
      loggedIn,
      user,
      togglePerfModal,
      toggleRequestPackModal,
      expert,
      toggleTravClanQuoteModal,
    } = this.props;
    const { detailsPopup } = this.state;
    const {
      fullName,
      email,
      mobile,
      walletBalance,
    } = user;

    const tierName = expert.tierName;
    const isEarlyTier = ['TRAINEE', 'RISING_STAR'].includes(tierName);
    // eslint-disable-next-line no-nested-ternary
    const tierIcon = tierName
      // eslint-disable-next-line no-nested-ternary
      ? (tierName === 'TRAINEE'
        ? traineeTierIcon
        // eslint-disable-next-line no-nested-ternary
        : (tierName === 'RISING_STAR'
          ? starTierIcon
          // eslint-disable-next-line no-nested-ternary
          : (tierName === 'GOLD'
            ? goldTierIcon
            : (tierName === 'DIAMOND'
              ? diamondTierIcon
              : platinumTierIcon))))
      : null;

    const { activeRequestPack } = expert;
    // eslint-disable-next-line no-nested-ternary
    const numerator = activeRequestPack
      ? (activeRequestPack.purchasedCount + activeRequestPack.bonusCount - activeRequestPack?.assignedCount)
      : (isEarlyTier
        ? (expert.totalLeadCount - expert.validLeadCount)
        : 0);
    // const showAnalyticIcon = window.location.pathname.split('/')[1] !== 'itineraryMaker';
    return (
      <AppBar
        position="static"
        className={classes.container}
        classes={{
          colorPrimary: classes.primaryColor,
        }}
      >
        <div className={classes.imgContainer}>
          <img src={flynote} alt="flynote" className={classes.img} />
        </div>
        <div className={classes.links}>
          {loggedIn ? (
            <div className={classes.loggedInArea}>
              <Tooltip title="Leads">
                <IconButton
                  className={clsx(classes.iconButton, _.last(window.location.pathname.split('/')) === 'dashboard' ? classes.selected : null)}
                  style={{ width: '130px' }}
                  onClick={() => { window.open('/dashboard', '_self'); }}
                >
                  <ListIcon />
                  <div style={{
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginLeft: '2px',
                    marginRight: '10px',
                  }}
                  >
                    Leads
                  </div>
                </IconButton>
              </Tooltip>
              {
                expert?.roles.includes('TEAM_LEAD')
                  ? (
                    <Tooltip title="Quote Calculator">
                      <IconButton
                        className={clsx(classes.iconButton)}
                        style={{ width: '130px' }}
                        onClick={() => {
                          toggleTravClanQuoteModal('');
                        }}
                      >
                        <MonetizationOnIcon />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                          }}
                        >
                          <div style={{
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            marginLeft: '2px',
                            marginRight: '10px',
                          }}
                          >
                            Quote
                          </div>
                          <div style={{
                            fontFamily: 'Lato',
                            fontSize: '12px',
                            marginLeft: '2px',
                            marginRight: '10px',
                          }}
                          >
                            Calculator
                          </div>
                        </div>
                        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.5 0H1.5C0.6675 0 0.00749999 0.6675 0.00749999 1.5L0 10.5C0 11.3325 0.6675 12 1.5 12H13.5C14.3325 12 15 11.3325 15 10.5V1.5C15 0.6675 14.3325 0 13.5 0ZM4.875 8.25H3.975L2.0625 5.625V8.25H1.125V3.75H2.0625L3.9375 6.375V3.75H4.875V8.25ZM8.625 4.695H6.75V5.535H8.625V6.48H6.75V7.3125H8.625V8.25H5.625V3.75H8.625V4.695ZM13.875 7.5C13.875 7.9125 13.5375 8.25 13.125 8.25H10.125C9.7125 8.25 9.375 7.9125 9.375 7.5V3.75H10.3125V7.1325H11.16V4.4925H12.0975V7.125H12.9375V3.75H13.875V7.5Z" fill="#FB923C" />
                        </svg>
                      </IconButton>
                    </Tooltip>
                  )
                  : null
              }
              <Tooltip title="Bible">
                <IconButton
                  className={classes.iconButton}
                  style={{ width: '130px' }}
                  onClick={() => {
                    window.open('https://drive.google.com/drive/u/0/folders/1kSQQWbRe20mTn6BTFqjpS21WPE-kvDv1', '_blank');
                  }}
                >
                  <MenuBookOutlinedIcon />
                  <div style={{
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginTop: '3px',
                    marginLeft: '2px',
                    marginRight: '10px',
                  }}
                  >
                    Bible
                  </div>
                </IconButton>
              </Tooltip>
              <Tooltip title="Trending Itineraries">
                <IconButton
                  className={clsx(classes.iconButton, _.last(window.location.pathname.split('/')) === 'templates' ? classes.selected : null)}
                  style={{ width: '130px' }}
                  onClick={() => {
                    window.open('/templates', '_self');
                  }}
                >
                  <PublicIcon />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                    }}
                  >
                    <div style={{
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      marginLeft: '2px',
                      marginRight: '10px',
                    }}
                    >
                      Trending
                    </div>
                    <div style={{
                      fontFamily: 'Lato',
                      fontSize: '12px',
                      marginLeft: '2px',
                      marginRight: '10px',
                    }}
                    >
                      Itineraries
                    </div>
                  </div>
                  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5 0H1.5C0.6675 0 0.00749999 0.6675 0.00749999 1.5L0 10.5C0 11.3325 0.6675 12 1.5 12H13.5C14.3325 12 15 11.3325 15 10.5V1.5C15 0.6675 14.3325 0 13.5 0ZM4.875 8.25H3.975L2.0625 5.625V8.25H1.125V3.75H2.0625L3.9375 6.375V3.75H4.875V8.25ZM8.625 4.695H6.75V5.535H8.625V6.48H6.75V7.3125H8.625V8.25H5.625V3.75H8.625V4.695ZM13.875 7.5C13.875 7.9125 13.5375 8.25 13.125 8.25H10.125C9.7125 8.25 9.375 7.9125 9.375 7.5V3.75H10.3125V7.1325H11.16V4.4925H12.0975V7.125H12.9375V3.75H13.875V7.5Z" fill="#FB923C" />
                  </svg>
                </IconButton>
              </Tooltip>
              {
                expert?.slabs?.length > 0
                  ? (
                    <>
                      <Tooltip title="Next Milestone">
                        <button
                          type="submit"
                          style={{
                            backgroundColor: 'transparent',
                          }}
                          onClick={expert?.roles?.includes(ROLES.TEAM_LEAD) ? this.analyticsHandler : togglePerfModal}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'end',
                              paddingBottom: 20,
                              width: 200,
                              position: 'relative',
                            }}
                          >
                            <ReactSpeedometer
                              customSegmentStops={[0, ...expert.slabs.map((s) => s.startCount)]}
                              segmentColors={['#FED7AA', '#FFAB70', '#FF7C2D', '#EA580C', '#C2410C', '#9A3412'].slice(0, expert.slabs.length)}
                              value={isEarlyTier ? expert.allTimeClosedCount : expert.thisMonthClosedCount}
                              needleHeightRatio={0.6}
                              maxValue={expert.slabs[expert.slabs.length - 1].startCount}
                              width={100}
                              height={50}
                              needleColor="white"
                              ringWidth={10}
                              customSegmentLabels={expert.slabs.map(() => { return {}; })}
                            />
                            {
                              ((expert.nextSlabGraduate || expert.nextSlabBonusAmount) && expert.nextSlabRemainingCount)
                                ? (
                                  <div
                                    style={{
                                      fontFamily: 'Lato',
                                      lineHeight: 1,
                                      textAlign: 'left',
                                      paddingBottom: 5,
                                      position: 'absolute',
                                      left: 90,
                                      top: 23,
                                    }}
                                  >
                                    <span style={{ fontSize: 14, fontWeight: 'bold' }}>
                                      {
                                        // eslint-disable-next-line no-nested-ternary
                                        expert.nextSlabBonusAmount
                                          ? `₹${Math.floor(expert.nextSlabBonusAmount/100000)}K Reward`
                                          : expert.nextSlabGraduate
                                            ? _.startCase(_.lowerCase(expert.nextTierName))
                                            : null
                                      }
                                    </span>
                                    <br />
                                    <span style={{ fontSize: 12 }}>on {expert.nextSlabRemainingCount} more booking{expert.nextSlabRemainingCount > 1 ? 's' : ''}</span>
                                  </div>
                                )
                                : null
                            }
                          </div>
                        </button>
                      </Tooltip>
                    </>
                )
                  : null
              }
              {
                expert?.tierName
                  ? (
                    <>
                      <Tooltip title="Performance">
                        <button
                          type="submit"
                          style={{
                            width: '68px',
                            backgroundColor: 'transparent',
                          }}
                          onClick={isEarlyTier ? togglePerfModal : toggleRequestPackModal}
                        >
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                          >
                            <div
                              style={{
                                fontFamily: 'Lato',
                                fontSize: 14,
                                fontWeight: 'bold',
                                lineHeight: '16px',
                              }}
                            >
                              {numerator}
                            </div>
                            <div style={{
                              color: expert.leadFlowOn ? '#20B038' : '#CE1515',
                              fontSize: 18,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginLeft: 5,
                            }}
                            >
                              <span className={clsx(classes.blinkingDot, expert.leadFlowOn ? classes.greenDot : classes.redDot)} />
                            </div>
                          </div>
                          <div style={{
                            fontFamily: 'Lato',
                            display: 'flex',
                            justifyContent: 'start',
                            lineHeight: '18px',
                          }}
                          >
                            <span style={{ fontSize: 12 }}>Leads Left</span>
                          </div>
                        </button>
                      </Tooltip>
                    </>
                  )
                  : null
              }
              <Button
                className={classes.profile}
                ref={this.detailsRef}
                onClick={() => this.handleToggle('detailsPopup')}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {
                    tierName
                      ? (
                        <div>
                          <img
                            src={tierIcon}
                            alt="[]"
                            className={classes.tierIcon}
                          />
                        </div>
                      )
                      : null
                  }
                  <div className={classes.userSection}>
                    {
                      expert.tierName
                        ? (
                          <div className={classes.tierName}>
                            {_.startCase(_.lowerCase(expert.tierName))}
                          </div>
                        )
                        : null
                    }
                    <div className={classes.expertName}>
                      {fullName}
                    </div>
                  </div>
                </div>
                <img src={userOutline} alt="user" className={classes.userIcon} />
              </Button>
              <Popper
                open={detailsPopup}
                anchorEl={this.detailsRef && this.detailsRef.current}
                transition
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    timeout={0.4}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <div>
                      <ClickAwayListener
                        onClickAway={this.handleDetailsClose}
                      >
                        <div className={classes.detailsPaper}>
                          <Arrow />
                          <div className={clsx(classes.listItem, classes.borderBottom)}>
                            <img alt=" " src={userOutline} className={classes.userAvatar} />
                            <div className={classes.details}>
                              <Typography style={{ fontSize: 16 }}>{fullName}</Typography>
                              <Typography className={classes.email}>{email}</Typography>
                              <Typography className={classes.mobile}>{mobile}</Typography>
                            </div>
                          </div>
                          {
                            expert.tierName
                              ? (
                                <div
                                  className={clsx(classes.listItem, classes.borderBottom)}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                  }}
                                >
                                  <div>
                                    <img
                                      src={tierIcon}
                                      alt="[]"
                                      style={{
                                        height: 40,
                                        width: 40,
                                      }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      marginLeft: 20,
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                      }}
                                    >
                                      {expert.tierName} Club
                                    </div>
                                    <button
                                      type="button"
                                      style={{
                                        color: '#2C7A77',
                                        fontWeight: 'bold',
                                        padding: 0,
                                        backgroundColor: 'transparent',
                                        textAlign: 'start',
                                      }}
                                      onClick={(e) => {
                                        togglePerfModal();
                                        this.handleDetailsClose(e);
                                      }}
                                    >
                                      View Details &gt;
                                    </button>
                                  </div>
                                </div>
                              )
                              : null
                          }
                          <Button
                            className={classes.listItem}
                          >
                            <div className={classes.iconContainer}>
                              <AccountBalanceWalletOutlinedIcon className={classes.icon} />
                            </div>
                            <Typography
                              className={classes.listText}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                              }}
                            >
                              <div style={{ fontSize: '14px' }}>
                                Wallet Balance
                              </div>
                              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                <b>₹{walletBalance / 100}</b>
                              </div>
                            </Typography>
                          </Button>
                          <Button
                            onClick={() => this.actionHandler('logout')}
                            className={classes.listItem}
                          >
                            <img src={logoutImg} alt=" " className={classes.logoutImg} />
                            <Typography className={classes.listText}>Logout</Typography>
                          </Button>
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Grow>
                )}
              </Popper>
            </div>
          ) : null}
        </div>
      </AppBar>
    );
  }
}

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
    padding: '10px 40px',
    boxSizing: 'border-box',
  },
  primaryColor: {
    borderRadius: 0,
    background: 'linear-gradient(90deg, #3AAEA9 0%, #16242A 100%)',
  },
  imgContainer: {
    height: 32,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    height: 26,
  },
  links: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  loggedInArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  action: {
    backgroundColor: 'transparent',
    padding: '0.25rem 0.5rem',
    color: theme.colors.white,
  },
  userSection: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 10,
    textAlign: 'left',
    paddingLeft: 5,
  },
  tierName: {
    color: theme.colors.white,
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
  },
  expertName: {
    color: theme.colors.white,
    fontSize: '12px',
    lineHeight: '12px',
  },
  role: {
    fontSize: 12,
    fontWeight: 300,
    color: theme.colors.white,
  },
  iconButton: {
    width: 40,
    height: 40,
    marginLeft: 10,
    borderRadius: '50%',
    color: theme.colors.white,
    minWidth: 0,
    minHeight: 0,
  },
  selected: {
    border: '1px solid rgba(255, 255, 255, 0.3)',
    borderRadius: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
  },
  profile: {
    marginLeft: 20,
    minHeight: 0,
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'none',
    padding: '0.25rem 0.5rem',
  },
  userIcon: {
    backgroundColor: 'rgba(255,255,255,0.1)',
    width: 38,
    height: 38,
    borderRadius: '50%',
    color: theme.colors.white,
  },
  notifArea: {
    position: 'relative',
  },
  notif: {
    marginTop: 6,
  },
  detailsPaper: {
    width: 330,
    minHeight: 160,
    backgroundColor: theme.colors.white,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: 10,
    zIndex: 2,
    marginTop: 18,
    border: `1px solid ${theme.colors.textLight_1}`,
    boxShadow: '0 6px 10px 0 rgba(0,0,0,0.24)',
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.colors.border}`,
  },
  details: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: 20,
  },
  userAvatar: {
    width: 40,
    height: 40,
    backgroundColor: theme.colors.primaryBackground,
    borderRadius: '50%',
  },
  mobile: {
    fontSize: 12,
  },
  email: {
    fontSize: 14,
  },
  listItem: {
    display: 'flex',
    flex: 1,
    outline: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    minHeight: 70,
    alignItems: 'center',
  },
  leadsImg: {
    width: 30,
    height: 30,
    padding: 6,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.primaryLight,
    borderRadius: '50%',
  },
  feedbackImg: {
    width: 30,
    height: 30,
    padding: 6,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.yellowLight,
    borderRadius: '50%',
  },
  logoutImg: {
    width: 30,
    height: 30,
    padding: 6,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.redLight,
    borderRadius: '50%',
  },
  iconContainer: {
    width: 30,
    height: 30,
    backgroundColor: '#DDDDDD',
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 18,
    height: 18,
    color: theme.colors.black,
  },
  listText: {
    flex: 1,
    color: theme.colors.black,
    fontSize: 18,
    display: 'flex',
    marginLeft: 20,
  },
  progressBar: {
    transform: 'rotate(180deg)',
    height: '6px',
    borderRadius: '5px',
    maxWidth: '100%',
    backgroundColor: '#e0e0e0',
  },
  progress: {
    height: '6px',
    borderRadius: '5px',
    backgroundColor: '#20B038',
  },
  orangeProgress: {
    height: '6px',
    borderRadius: '5px',
    backgroundColor: '#E67E22',
  },
  redProgress: {
    height: '6px',
    borderRadius: '5px',
    backgroundColor: '#CC3254',
  },
  tierIcon: {
    height: 30,
    width: 30,
  },
  '@keyframes blink': {
    '0%, 100%': { opacity: 1 },
    '50%': { opacity: 0 },
  },
  blinkingDot: {
    display: 'inline-block',
    width: 12,
    height: 12,
    borderRadius: '50%',
    animation: '$blink 1s infinite',
    marginRight: 10,
  },
  greenDot: {
    backgroundColor: '#20B038',
  },
  redDot: {
    backgroundColor: '#CE1515',
  },
});

Navbar.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  actionHandler: PropTypes.func.isRequired,
  user: PropTypes.object,
  classes: PropTypes.object,
  togglePerfModal: PropTypes.func,
  toggleRequestPackModal: PropTypes.func,
  expert: PropTypes.object,
  toggleTravClanQuoteModal: PropTypes.func,
};

export default withStyles(styles)(Navbar);
