import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import * as moment from 'moment';
import * as _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
// import FavOutline from '@material-ui/icons/StarBorder';
// import FavFilled from '@material-ui/icons/Star';
import withStyles from '@material-ui/styles/withStyles';
import { isEqual } from 'utils/common';
import Tooltip from '@material-ui/core/Tooltip';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import fireImg from 'assets/svg/fire.svg';
import burningFireImg from 'assets/svg/burning-fire.svg';
import { LinearProgress } from '@material-ui/core';

class RequestCard extends Component {
  state = { highlightChange: false };

  componentDidUpdate = (prevProps) => {
    const { selected, request } = this.props;
    if (!isEqual(prevProps.request, request) && selected && prevProps.request) {
      this.setState({ highlightChange: true }, () => {
        setTimeout(() => {
          this.setState({ highlightChange: false });
        }, 2000);
      });
    }
  };

  render() {
    const {
      request,
      index,
      selected,
      cardActionHandler,
      isCardSelected,
      canReAssign,
      expert,
      classes,
      meta,
      toggleChaseScoreModal,
    } = this.props;
    const { highlightChange } = this.state;
    let firstName;
    let lastName;
    const {
      primaryCustomer,
      primaryCustomerData = {},
      usId,
      // starred,
      interested,
      requirement = {},
      status = '',
      priorityMessage,
      tags = [],
      b2bPartner,
      requestedExpert,
      labels = [],
    } = request;
    const b2bPartners = meta?.b2bPartners || [];
    if (primaryCustomer && primaryCustomer.email !== 'cred.bookings@flynote.in') {
      firstName = primaryCustomer.firstName || '';
      lastName = primaryCustomer.lastName || '';
    } else {
      firstName = primaryCustomerData.firstName || '';
      lastName = primaryCustomerData.lastName || '';
    }
    const {
      places,
      departureDate,
      travellers,
    } = requirement;
    // return useMemo(() => {
    const requestStatus = status || '';
    //     let requestTime = '';
    //     if (lastActivityAt) {
    //       const nowTime = moment().unix();
    //       requestTime = moment(lastActivityAt)
    //         .unix();
    //       requestTime = Math.floor((nowTime - requestTime) / 36);
    //       if (requestTime >= 1440) {
    //         requestTime = `${Math.floor(requestTime / 1440)}
    // day ${Math.floor((requestTime % 1440) / 60)} hours \
    // ${Math.floor((requestTime / 1440) % 60)} minutes`;
    //       } else if (requestTime > 59) {
    //         requestTime = `${Math.floor(requestTime / 60)} hour ${requestTime % 60} minutes`;
    //       } else {
    //         requestTime = `${requestTime} minutes`;
    //       }
    //       requestTime = `Updated ${requestTime} ago`;
    //     }
    let traveller = ' ';
    if (travellers) {
      const { adults, children, infants } = travellers;
      traveller = `${departureDate ? ', ' : ''}${adults + children + infants} travellers`;
    }
    const isDiffOwner = expert._id !== request.expert?._id;
    return (
      <div className={clsx(classes.container, selected && classes.selectedCard)}>
        <div
          role="button"
          tabIndex="0"
          onClick={(e) => cardActionHandler(e, 'set_active', request, index)}
          className={clsx(
            classes.cardContainer,
            selected && classes.selected,
            highlightChange && classes.highlight
          )}
        >
          <div className={selected ? classes.subSelection : ''}>
            <div className={classes.card}>
              <div className={classes.pinSection}>
                {canReAssign ? (
                  <Checkbox
                    checked={isCardSelected}
                    onChange={(e) => cardActionHandler(e, 'assignment', request, index)}
                    color="primary"
                    className={classes.cardSelect}
                    // icon={<CheckboxIcon />}
                    disableFocusRipple
                    disableRipple
                    inputProps={{
                      'aria-label': 'card-select',
                    }}
                  />
                ) : null}
                {/* <Checkbox */}
                {/*  checked={Boolean(starred)} */}
                {/*  onChange={(e) => cardActionHandler(e, 'pin', request, index)} */}
                {/*  className={classes.favCheckbox} */}
                {/*  disableFocusRipple */}
                {/*  disableRipple */}
                {/*  checkedIcon={<FavFilled className={classes.selectedIcon} />} */}
                {/*  icon={<FavOutline className={classes.icon} />} */}
                {/*  inputProps={{ */}
                {/*    'aria-label': 'checkbox', */}
                {/*  }} */}
                {/* /> */}
                <Checkbox
                  title={interested ? 'Interested' : 'Not Interested'}
                  checked={Boolean(interested)}
                  onChange={(e) => cardActionHandler(e, 'interested', request, index)}
                  className={classes.favCheckbox}
                  disableFocusRipple
                  disableRipple
                  checkedIcon={<img height="20px" alt="Interested" src={burningFireImg} />}
                  icon={<img height="20px" alt="Not Interested" src={fireImg} />}
                  inputProps={{
                    'aria-label': 'checkbox',
                  }}
                />
              </div>
              <div className={classes.nameSection}>
                <Typography className={classes.name}>{`${firstName} ${lastName}`}</Typography>
                <Typography className={classes.id}>
                  {`(${usId})${tags.find((tag) => tag.includes('premium')) ? ' $$' : ''}${requestedExpert ? ' (Profile Lead)' : ''}`}
                </Typography>
              </div>
              <div className={classes.detailsSection}>
                <Typography className={classes.destinationName}>
                  {places ? places.map((d) => (_.startCase(d?.normalizedName) || ''))
                    .join(', ') : 'Destination not decided'}
                </Typography>
                <div className={classes.destinationDetails}>
                  <Typography variant="subtitle2" className={classes.dateValue}>
                    {departureDate ? moment(departureDate).format('DD MMM') : null}
                    {traveller}
                  </Typography>
                </div>
              </div>
              <Typography align="right" className={classes.status}>
                {requestStatus && requestStatus.replace(/_/g, ' ')}
                <div className={classes.flex}>
                  {
                    (tags && tags.includes('DATES_NOT_FINAL'))
                      ? (
                        <Tooltip
                          title="Dates not final"
                          tabIndex="-1"
                          className={classes.tooltip}
                        >
                          <EventBusyIcon
                            className={classes.tags}
                          />
                        </Tooltip>
                      ) : null
                  }
                  {
                    (tags && tags.includes('WALLET_CUSTOMER'))
                      ? (
                        <Tooltip
                          title="Wallet customer"
                          tabIndex="-1"
                          className={classes.tooltip}
                        >
                          <AccountBalanceWalletIcon
                            className={classes.wallet}
                          />
                        </Tooltip>
                      ) : null
                  }
                </div>
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '25px',
                marginBottom: '10px',
              }}
            >
              {
                labels.map((label) => {
                  return (
                    <div
                      className={classes.label}
                      style={{
                        color: label.color,
                        border: `1px solid ${label.color}`,
                      }}
                    >
                      {
                        label.text.split('_').map((t) => _.startCase(t.toLowerCase())).join(' ')
                      }
                    </div>
                  );
                })
              }
            </div>
          </div>
          {
            b2bPartner
              ? (
                <div className={classes.addedAt}>
                  <Typography>
                    B2b Partner:
                    {
                      b2bPartners.filter(
                        (partner) => partner._id === b2bPartner
                      ).map(
                        (partner) => partner.name || partner._id
                      ).join(', ')
                    }
                  </Typography>
                </div>
              )
              : null
          }
          <div className={classes.addedAt}>
            <div style={{
              padding: '2px 5px',
              margin: '2px 5px',
              cursor: 'pointer',
              fontSize: 12,
            }}
            >
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}
              >
                Chase Score
                <InfoOutlinedIcon
                  style={{
                    marginLeft: 5,
                    height: 12,
                    width: 12,
                  }}
                  onClick={() => toggleChaseScoreModal()}
                />
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              >
                <div className={classes.progressValue}>
                  {request.chaseScore}
                </div>
                <LinearProgress
                  style={{ height: 8 }}
                  variant="determinate"
                  value={request.chaseScore}
                  classes={{
                    root: classes.progressBar,
                    bar: classes.progress,
                  }}
                />
              </div>
            </div>
            <Typography className={classes.addedAtText}>{priorityMessage}</Typography>
            <div style={{ flex: 1 }}>
              {
                _.isNil(request.followUpTime)
                  ? null
                  : (
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '14px',
                      fontFamily: 'Lato',
                      color: '#000000',
                      justifyContent: 'flex-end',
                    }}
                    >
                      <AlarmOnOutlinedIcon style={{ marginRight: '5px', width: '18px', height: '18px' }} />
                      {moment(request.followUpTime).utcOffset('+0530').format('DD MMM, h:mmA')}
                    </div>
                  )
              }
              {
                isDiffOwner
                  ? (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                    >
                      <Typography
                        className={classes.ownerText}
                      >
                        Owner:
                      </Typography>
                      <Typography
                        className={classes.ownerName}
                      >
                        {request.expert?.fullName}
                      </Typography>
                    </div>
                  )
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    flexShrink: 0,
  },
  index: {
    marginRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 16,
    paddingTop: 20,
  },
  selectedIndex: {
    color: theme.colors.primary,
    fontWeight: 'bold',
  },
  pinSection: {
    width: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wallet: {
    color: '#369f9b',
    marginLeft: 15,
  },
  tags: {
    color: '#369f9b',
    marginLeft: 15,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  cardContainer: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    transition: 'background-color 1s, padding 0.1s ease-in',
    margin: '0 30px 0 0',
    cursor: 'pointer',
    borderRadius: 10,
    outline: 'none',
    border: 'none',
    boxSizing: 'border-box',
    '&:hover': {
      boxShadow: '0 2px 12px -2px rgba(0,0,0,0.2)',
    },
  },
  highlight: {
    backgroundColor: 'rgba(57, 172, 167, 0.3)',
  },
  cardSelect: {
    padding: 0,
    marginBottom: 6,
  },
  card: {
    display: 'flex',
    flex: 1,
    padding: '10px 20px',
    flexDirection: 'row',
  },
  favCheckbox: {
    padding: 0,
  },
  icon: {
    width: 18,
    height: 18,
  },
  selectedIcon: {
    width: 18,
    height: 18,
    color: theme.colors.yellow,
  },
  nameSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 10,
  },
  detailsSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  destinationName: {
    fontSize: 12,
  },
  destinationDetails: {
    display: 'flex',
    flexDirection: 'row',
  },
  dateValue: {
    fontSize: 14,
    color: theme.colors.textLight,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  name: {
    color: theme.colors.textDark,
    fontSize: 14,
    fontWeight: 'bold',
  },
  id: {
    color: theme.colors.textLight,
    fontSize: 12,
  },
  status: {
    flex: 1,
    fontSize: 12,
    color: theme.colors.orange,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  addedAt: {
    borderTop: `1px solid ${theme.colors.underline}`,
    padding: '6px 20px',
    display: 'flex',
    flexDirection: 'row',
  },
  addedAtText: {
    color: theme.colors.red,
    fontSize: 14,
    flex: 2,
  },
  ownerName: {
    fontSize: 14,
    color: theme.colors.textDark,
  },
  ownerText: {
    paddingRight: 6,
    fontSize: 14,
    color: theme.colors.textLight,
  },
  subSelection: {
    paddingRight: 30,
  },
  selected: {
    boxShadow: '0 2px 12px -2px rgba(0,0,0,0.2)',
    margin: 0,
    zIndex: 1,
    borderRadius: '10px 0 0 10px',
  },
  selectedCard: {
    border: `1px solid ${theme.colors.primary}`,
    borderRadius: '10px 0 0 10px',
    borderRightColor: 'transparent',
    zIndex: 2,
  },
  label: {
    minWidth: '60px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '10px',
    paddingLeft: '10px',
    marginRight: '10px',
    borderRadius: '50px',
    fontSize: '12px',
  },
  progressValue: {
    fontSize: 14,
    color: theme.colors.primary,
    fontWeight: 'bold',
  },
  progressBar: {
    flex: 1,
    marginLeft: 5,
    borderRadius: 5,
  },
  progress: {
    height: '8px',
    borderRadius: '8px',
    backgroundColor: theme.colors.primary,
  },
});

RequestCard.propTypes = {
  classes: PropTypes.object,
  cardActionHandler: PropTypes.func,
  isCardSelected: PropTypes.bool,
  onSelected: PropTypes.func,
  selected: PropTypes.bool,
  request: PropTypes.object.isRequired,
  index: PropTypes.number,
  isFavorite: PropTypes.bool,
  expert: PropTypes.object.isRequired,
  canReAssign: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
  toggleChaseScoreModal: PropTypes.func,
};

export default withStyles(styles)(RequestCard);
