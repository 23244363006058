import MomentUtils from '@date-io/moment';
import ReviveIcon from '@material-ui/icons/Autorenew';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from 'UI/button';
import Header from 'UI/header';
import LabelInput from 'UI/labelInput';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';

const Revival = ({
  onRevive,
  onDismiss,
  activeRequest,
  showSnackbar,
}) => {
  const classes = useStyles();
  const [reason, setReason] = useState('');
  const [possibleBookingDate, setPossibleBookingDate] = useState(null);
  const handleSubmit = () => {
    if (_.isEmpty(reason.trim()) && _.isNil(possibleBookingDate)) {
      showSnackbar('Please provide all the details', 'error');
      return;
    }
    onRevive({ details: { reason, possibleBookingDate: moment(possibleBookingDate).format('YYYY-MM-DD') } });
  };
  return (
    <div className={classes.container}>
      <Header
        title="Revive Request"
        Icon={ReviveIcon}
        onDismiss={onDismiss}
      />
      <div className={classes.body}>
        {
          _.isNil(activeRequest.fisAt)
            ? (
              <div>
                <LabelInput
                  label="Reason for reviving"
                  placeholder="Reason for reviving"
                  inputClass={classes.input}
                  inputProps={{
                    multiline: true,
                  }}
                  value={reason}
                  onChange={(e) => setReason(e)}
                />
              </div>
            )
            : (
              <div>
                <div style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  marginBottom: 5,
                  color: '#666666',
                }}
                >
                  Update Client Booking Status
                </div>
                <div style={{ fontSize: 14 }}>
                  Itinerary has been sent to this client for <b>over {moment().diff(moment(activeRequest.fisAt), 'days')} days now</b>, please update on the client booking status.
                  <br />
                  <br />
                  If the customer is still planning, enter a probable booking date
                </div>
                <div style={{ width: '100%' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      disablePast
                      value={possibleBookingDate}
                      placeholder="Select Date"
                      onChange={(dt) => setPossibleBookingDate(dt)}
                      className={classes.dateTimeInput}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            )
        }
        <div className={classes.footer}>
          <Button
            disabled={_.isNil(possibleBookingDate) && _.isEmpty(reason.trim())}
            className={classes.submitButton}
            onClick={handleSubmit}
          >
            Revive
          </Button>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    flex: 1,
    flexDirection: 'column',
    minWidth: 420,
  },
  body: {
    fontFamily: 'Lato',
    padding: '10px 30px 30px 30px',
    flex: 1,
    flexDirection: 'column',
  },
  input: {
    height: 64,
  },
  dateTimeInput: {
    cursor: 'pointer',
    '& > div > input': {
      cursor: 'pointer !important',
    },
  },
  submitButton: {
    backgroundColor: '#3AAEA9',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '30px',
    marginTop: 20,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

Revival.propTypes = {
  onRevive: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  activeRequest: PropTypes.object,
  showSnackbar: PropTypes.func,
};

export default Revival;
